import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../../components/website/Footer";
import HeaderConferences from "../../components/website/HeaderConferences";
import api from "../../api/endpoints/events.js";
import Breadcrumbs from "../../components/Breadcrumbs";

function SingleGallery() {
  const [galleryImages, setGalleryImages] = useState([]);
  const [displayImages, setDisplayImages] = useState([]);
  const { id } = useParams();
  const [enlargedImg, setEnlargedImg] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(24);
  const [loaded, setLoaded] = useState(false);

  const openEnlarged = (src, index) => {
    setEnlargedImg(src);
    setCurrentImageIndex(index);
    autoLoadMore(index);
  };

  const closeEnlarged = () => {
    setEnlargedImg(null);
    setCurrentImageIndex(null);
  };

  const handleKeyDown = (event) => {
    if (!enlargedImg) return; // Реагировать на клавиши только если открыто увеличенное изображение
    if (event.key === 'ArrowRight') {
      switchImage('next');
    } else if (event.key === 'ArrowLeft') {
      switchImage('prev');
    }
  };

  const switchImage = (direction) => {
    if (displayImages.length === 0) return;

    let newIndex = currentImageIndex;

    if (direction === 'prev' && currentImageIndex > 0) {
      newIndex = currentImageIndex - 1;
    } else if (direction === 'next' && currentImageIndex < displayImages.length - 1) {
      newIndex = currentImageIndex + 1;
    } else {
      return; // Если достигнут конец или начало списка, не делаем ничего
    }

    openEnlarged(displayImages[newIndex].url, newIndex);
  };

  const autoLoadMore = (index) => {
    // Автоматическая подгрузка, если пользователь достигает последнего изображения текущей страницы
    if (index === displayImages.length - 1 && displayImages.length < galleryImages.length) {
      loadMoreImages();
    }
  };

  const fetchData = async () => {
    try {
      const response = await api.get(`/Event/list/${id}`);
      setGalleryImages(response.data);
      setDisplayImages(response.data.slice(0, itemsPerPage));
      setLoaded(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [enlargedImg, currentImageIndex, displayImages]); // Обновляем слушатели, если изменяется состояние

  const loadMoreImages = () => {
    const nextItems = galleryImages.slice(displayImages.length, displayImages.length + itemsPerPage);
    setDisplayImages(displayImages.concat(nextItems));
  };

  return (
      <div>
        <HeaderConferences />
        <main>
          <section>
            <div className="container">
              <div className="breadcrumbs-about breadcrumbs-add">
                <ul>
                  <li className="andThisStyle title-breadcrumbs">
                    <a href="/">MAIN</a>
                  </li>
                  <li className="thisStyle description-breadcrumbs second-bread">
                    <a href="/gallery">PAST EVENTS</a>
                  </li>
                  <li className="thisStyle description-breadcrumbs post-description">GALLERY</li>
                </ul>
                <Breadcrumbs />
              </div>
            </div>
          </section>
          <section>
            <div className="container">
              <div className="photogallery-events-wrapper gallery-item-wrapper">
                {displayImages.map((image, index) => (
                    <div className="online-item gallery-item" key={index} onClick={() => openEnlarged(image.url, index)}>
                      <img src={image.url} alt={`Image ${index}`} />
                    </div>
                ))}
              </div>
              {(displayImages.length < galleryImages.length) && (
                  <div className="load-more-container">
                    <button className="sponsors-button" onClick={loadMoreImages}>
                      Load More
                    </button>
                  </div>
              )}
            </div>
            {enlargedImg && (
                <div className="enlarged-background">
                  <div className="enlarged-overlay" onClick={closeEnlarged}>
                    <div className="enlarged" onClick={closeEnlarged}>
                      <img src={enlargedImg} alt="Enlarged Image" />
                    </div>
                  </div>
                </div>
            )}
            {currentImageIndex !== null && (
                <div className="gallery-navigation">
                  <button onClick={() => switchImage('prev')}>Prev</button>
                  <button onClick={() => switchImage('next')}>Next</button>
                </div>
            )}
          </section>
        </main>
        <Footer />
      </div>
  );
}

export default SingleGallery;
