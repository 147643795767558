import React, { useEffect, useState, useRef } from "react";
import Footer from "../../components/website/Footer";
import HeaderConferences from "../../components/website/HeaderConferences";

import api from "../../api/endpoints/events.js";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import Slider from "rc-slider";
import { all } from "axios";
import Breadcrumbs from "../../components/Breadcrumbs";


const marks = {
  1: <span className="conference-marcs-mobile">Jan.</span>,
  2: <span className="conference-marcs-mobile">Feb.</span>,
  3: <span className="conference-marcs-mobile">Mar.</span>,
  4: <span className="conference-marcs-mobile">Apr.</span>,
  5: <span className="conference-marcs-mobile">May</span>,
  6: <span className="conference-marcs-mobile">Jun.</span>,
  7: <span className="conference-marcs-mobile">Jul.</span>,
  8: <span className="conference-marcs-mobile">Aug.</span>,
  9: <span className="conference-marcs-mobile">Sep.</span>,
  10: <span className="conference-marcs-mobile">Oct.</span>,
  11: <span className="conference-marcs-mobile">Nov.</span>,
  12: <span className="conference-marcs-mobile">Dec.</span>,
};

const fullMarks = {
  1: <span className="conference-marcs">January</span>,
  2: <span className="conference-marcs">February</span>,
  3: <span className="conference-marcs">March</span>,
  4: <span className="conference-marcs">April</span>,
  5: <span className="conference-marcs">May</span>,
  6: <span className="conference-marcs">June</span>,
  7: <span className="conference-marcs">July</span>,
  8: <span className="conference-marcs">August</span>,
  9: <span className="conference-marcs">September</span>,
  10: <span className="conference-marcs">October</span>,
  11: <span className="conference-marcs">November</span>,
  12: <span className="conference-marcs">December</span>,
};

function Conferences() {
  const [allConferences, setAllConferences] = useState([]);
  const [hoveredId, setIsHovered] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [activeConferences, setActiveConferences] = useState([]);
  const [currentMarks, setCurrentMarks] = useState(marks);
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const updateScreenSize = () => {
    setScreenSize(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', updateScreenSize);
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  const handleStyle = screenSize < 600 ? {
    borderColor: "#1089F1",
    height: "25px",
    width: "24px",
    bottom: "5px",
    backgroundColor: "rgb(0, 0, 160)",
    zIndex: '1',
    opacity: '1',
    border: '7px solid rgb(222,184,135)',
    // left: '3%',
  } : {
    borderColor: "#1089F1",
    height: "44px",
    width: "44px",
    bottom: "0",
    backgroundColor: "rgb(0, 0, 160)",
    zIndex: '1',
    opacity: '1',
    border: '15px solid rgb(222,184,135)',
  }

  const trimDescription = (description, maxLength) => {
    if(description !== null)
      return description.length > maxLength ? description.slice(0, maxLength - 3) + '...' : description;
  };

    const getAllEventsAndAdjustMonth = async () => {
      try {
        const { data } = await api.get("/Event/get-all-events?pageNumber=1&pageSize=100");
        setAllConferences(data.items);
  
        const sortedConferences = data.items.sort((a, b) => {
          const dateA = new Date(a.endDate);
          const dateB = new Date(b.endDate);
          return dateA - dateB;
        });
  
        // const filteredOnes = sortedConferences.filter((item) => {
        //   return new Date(item.endDate).getMonth() + 1 === selectedMonth;
        // });
  
        // setActiveConferences(filteredOnes);
        
        setActiveConferences(sortedConferences);

        // Check if there are active conferences in the selected month.
        const conferencesInSelectedMonth = sortedConferences.filter((item) => {
          return new Date(item.endDate).getMonth() + 1 === selectedMonth;
        });
  
        // If there are no active conferences in the selected month,
        // find the next available month with conferences.
        if (conferencesInSelectedMonth.length === 0) {
          let nextAvailableMonth = selectedMonth + 1;
          while (nextAvailableMonth <= 12) {
            const conferencesInNextMonth = sortedConferences.filter((item) => {
              return new Date(item.endDate).getMonth() + 1 === nextAvailableMonth;
            });
  
            if (conferencesInNextMonth.length > 0) {
              setSelectedMonth(nextAvailableMonth);
              break;
            }
  
            nextAvailableMonth++;
          }
        }
      } catch (err) {
        console.log("error", err);
      }
    };
    
  const changeSlider = (e) => {
    console.log('im here');
    console.log(e[0]);
    setSelectedMonth(e[0]);
    const filteredOnes = allConferences.filter((item) => {
      return new Date(item.endDate).getMonth() + 1 === e[0];
    });
    console.log(filteredOnes);

    setActiveConferences(filteredOnes);
  };
  

  const formateDate = (date) => {
    const { startDate, endDate } = date;
    const formattedDate = new Date(startDate);
    const [startDay, endDay] = [
      formattedDate.getDate(),
      new Date(endDate).getDate(),
    ];
    const month = formattedDate.toLocaleString("en-US", { month: "short" })
     return `${month}. ${startDay} - ${endDay}, ${new Date(endDate).getFullYear()}`;
  };

  const handleMouseEnter = (id) => {
    setIsHovered(id);
  };

  const handleMouseLeave = () => {
    setIsHovered(null);
  };

  useEffect(() => {
    getAllEventsAndAdjustMonth();
  }, []);

  useEffect(() => {
    const updateMarks = () => {
      if (window.innerWidth > 1023) {
        setCurrentMarks(fullMarks);
      } else {
        setCurrentMarks(marks);
      }
    }

    updateMarks();
    window.addEventListener('resize', updateMarks);

    return () => {
      window.removeEventListener('resize', updateMarks);
    }
  }, []);

  useEffect(() => {
    const setEqualHeight = () => {
      const elements = document.querySelectorAll('.col-class-upcoming');
      let maxHeight = 0;
      elements.forEach(element => {
        const height = element.offsetHeight;
        if (height > maxHeight) {
          maxHeight = height;
        }
      });
      elements.forEach(element => {
        element.style.height = `${maxHeight}px`;
      });
    };

    setEqualHeight();
    window.addEventListener('resize', setEqualHeight);

    return () => {
      window.removeEventListener('resize', setEqualHeight);
    };
  }, []);

  return (
    <div>
      <HeaderConferences />
      <main>
        <section>
          <div className="container">
            <div className="breadcrumbs-about breadcrumbs-add">
              <ul>
                <li className="andThisStyle title-breadcrumbs">
                  <Link to="/">MAIN</Link>
                </li>
                <li className="thisStyle description-breadcrumbs">
                  B2B CONFERENCES
                </li>
              </ul>
              <Breadcrumbs />
            </div>
            <h2 className="title-conferences" id="conferences-title">
              B2B conferences
            </h2>
            <div className="line-conferences line-contact-us line-event-us">
              <span className="line-about"></span>
            </div>
          </div>
        </section>

        <div className="slider-rc container upcoming-container">
          <Slider
            className="conference-text-upcoming container"
            min={1}
            max={12}
            onChange={(e) => changeSlider(e)}
            step={1}
            range
            value={selectedMonth}
            railStyle={{ backgroundColor: "#00005A", height: "1px", bottom: "17px", }}
            handleStyle={handleStyle}
            dotStyle={{
              borderColor: "transparent",
              backgroundColor: "transparent",
              width: "0",
              height: "0",
              bottom: "0px",
              zIndex: "2",
            }}
            marks={currentMarks}
          />
          {/*<div className="marks-container">*/}
          {/*{Object.values(currentMarks).map((marks, index) =>*/}
          {/*  <span key={index}>{marks}</span>*/}
          {/*)}*/}
          {/*</div>*/}
        </div>

        <section className="conference-section">
          <div>
            <div className="container special-container b2b" id="all-events-container">
            <div className="container-upcoming-events">
              <div className="conference-events-wrapper">
              {activeConferences?.map((conference) => (
                <div className="col-class-upcoming" key={conference.id}>
                <div
                  key={conference.id}
                  // className="content active addactive"
                  data-month={new Date(conference.endDate).getMonth() + 1}
                  onMouseEnter={() => handleMouseEnter(conference.id)}
                  onMouseLeave={handleMouseLeave}
                >
                  <div
                    id="content-conferences"
                    className="conferences-cards content-conferences container-content hover-upcoming"
                    style={{
                      background: `${
                        hoveredId === conference.id
                          ? `url(${conference.backgroundImage})`
                          : ""
                      }`,
                    }}
                  >
                    <div className="data-content conference-data-content">
                      {formateDate(conference)}
                      <div className="conference-location">{conference.location}</div>
                    </div>
                    <div className="conference-block-wrapper">
                      <Link
                          className="conference-link"
                          to={`/single-conference/${conference.slug}`}
                      >
                        <h2 className="title-content conference-content-title">{conference.eventName}</h2>
                      </Link>
                      <div>
                        <span className="line-slider line-conference-new"></span>
                      </div>
                      <p className="description-content conference-content-description">
                        {trimDescription(conference.eventDescription)}
                      </p>
                      <Link
                          className="conference-link conference-data-link"
                          to={`/single-conference/${conference.slug}`}
                      >
                      <button className="btn-content conference-data-btn">
                          Details
                      </button>
                      </Link>
                    </div>
                  </div>
                </div>
                </div>
              ))}
              </div>
            </div>
            </div>
          </div>
        </section>
      </main>
      <div id="conference-footer"></div>

      <Footer />
    </div>
  );
}

export default Conferences;
