import React, {useEffect, useState} from 'react';
import Footer from '../../components/website/Footer';
import HeaderConferences from '../../components/website/HeaderConferences';
import Breadcrumbs from "../../components/Breadcrumbs";
import axios from 'axios';
import {useParams} from "react-router-dom";
import api from "../../api/endpoints/events";
import {NotificationManager} from "react-notifications";
import { ThreeDots } from "react-loader-spinner";

function Unsubscribe() {
    const [isLoading, setIsLoading] = useState(false);

    const { id } = useParams();
    const [conference, setConference] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                window.scrollTo(0, 0);

                const { data } = await api.get(`/Event/get-event-by-slug/${id}`);
                if(data.endDate == null) {
                    setIsValidId(false);
                    return;
                }
                setConference(data);
            } catch (error) {
            }
        };
        fetchData();
    }, [id]);


    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        const email = event.target.email.value;
        const reason = event.target.reason.value;

        const googleScriptURL = "https://script.google.com/macros/s/AKfycbwnkBUnzmBnW9piHmyc2ZOJHwZtnoO1st9hLW-T7-RIJI3xyF1Q8Sj2wFwIkOMrimw6/exec";

        const formData = new FormData();
        formData.append("event", conference.eventName);
        formData.append("email", email);
        formData.append('reason', reason);

        axios.post(googleScriptURL, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
                },
            })
            .then((response) => {
                NotificationManager.success('You have successfully unsubscribed from our newsletters!\n', 'Success', 5000);
                document.getElementById('email').value = '';
                document.getElementById('reason').value = '';
                setIsLoading(false);
            })
            .catch((error) => {
                console.log('There was a problem with the axios operation: ', error);
                setIsLoading(false);
             });

    }
    return (
        <div>
            <HeaderConferences />
            <main>
                <section>
                    <div className="background-block unsubscribe-block">
                        <div className="container">
                            <div className="breadcrumbs-about">
                                <ul>
                                    <a href="/">
                                        <li className="andThisStyle title-breadcrumbs unsubscribe-breadcrumbs">MAIN</li>
                                    </a>
                                    <li className="thisStyle description-breadcrumbs unsubscribe-breadcrumbs">
                                        UNSUBSCRIBE
                                    </li>
                                </ul>
                                <Breadcrumbs />
                            </div>
                            <div className="background-single"></div>
                            <img className="unsubscribe-image" style={{
                                backgroundImage: `url(${conference.eventImage})`,
                                backgroundSize: 'cover'
                            }} />
                            <div className="centered-block">
                                <h2 className="unsubscribe-title">Unsubscribe form</h2>
                                <form action="/submit" method="post" className="unsubscribe-form" onSubmit={handleSubmit}>
                                    <label htmlFor="email">Your Email (required)*:</label>
                                    <input className="unsubscribe-email" type="email" id="email" name="email" required/>
                                    <br/>
                                    <label htmlFor="reason">Reason*:</label>
                                    <textarea type="text" id="reason" name="reason" required/>
                                    <br/>
                                    <button className="unsubscribe-btn">
                                        {isLoading ? <ThreeDots height="40" width="40" color="white" /> : "Submit"}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}

export default Unsubscribe;