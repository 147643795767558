import React, { useState, useEffect, useRef } from "react";

const Modal = ({ isOpen, onClose, children, types, currentType }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const modalContentRef = useRef();

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleOverlayClick = (event) => {
      if (event.target.classList.contains("modal-overlay")) {
        onClose();
      }
    };

    document.addEventListener("click", handleOverlayClick);

    return () => {
      document.removeEventListener("click", handleOverlayClick);
    };
  }, [isOpen]);

  const handleModalContentClick = (event) => {
    if (
        modalContentRef.current &&
        !modalContentRef.current.contains(event.target)
    ) {
      handleClose();
    }
  };

  if (!isOpen || !types.includes(currentType)) return null;

  return (
      <div>
        <div className="modal-overlay" onClick={handleModalContentClick}>
          <button title="Close (Esc)" type="button" className="mfp-close close-button" onClick={handleClose}>
            <div className="line1"></div>
            <div className="line2"></div>
          </button>

          <div className="modal">
            <div ref={modalContentRef} className="modal-content">
              {children}
            </div>
          </div>
        </div>
      </div>
  );
};

export default Modal;