import React, { useEffect } from "react";
import Footer from "../../components/website/Footer";
import HeaderConferences from "../../components/website/HeaderConferences";
import Breadcrumbs from "../../components/Breadcrumbs";

function Cancelation() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <HeaderConferences />
            <main>
                <section>
                    <div className="container">
                        <div class="breadcrumbs-about breadcrumbs-add">
                            <ul>
                                <li className="andThisStyle title-breadcrumbs">MAIN</li>
                                <li className="thisStyle description-breadcrumbs">
                                    Cancelation policy
                                </li>
                            </ul>
                            <Breadcrumbs />
                        </div>
                        <h2 className="title-conferences title-contact-us event-contact-us">
                            Cancelation policy
                        </h2>
                        <div className="line-conferences line-contact-us line-event-us">
                            <span className="line-about"></span>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <h3 className="title-terms">Payment Policy:</h3>
                        <p className="text-terms">
                            Registration fees must be paid in full within 14 working days of the invoice issue date unless
                            otherwise agreed. Payment methods include bank transfer, credit card, or PayPal. Please note
                            that any additional bank transfer fees incurred due to foreign currency transactions are the
                            responsibility of the payer. Payment must be received in full before the event start date.
                            All prices listed are exclusive of VAT.
                        </p>
                        <h3 className="title-terms">Cancellation Policy and Refunds:</h3>
                        <p className="text-terms">
                            Cancellation of registration is accepted in written form via email to the financial department
                            only. If cancellation is made within 24 hours after registration, a full refund of the registration
                            fee will be provided. Cancellations made more than 24 hours after registration will not be eligible
                            for a refund; however, the client will receive a voucher valid for one year from the original event
                            start date, which can be used for any other event hosted by “BCF”. Please note that vouchers cannot
                            be refunded in case of further cancellations.
                        </p>
                        <h3 className="title-terms">
                            Force Majuro and Permanent Cancellation of Event:
                        </h3>
                        <p className="text-terms">
                            BCF reserves the right to change event dates, sites, or location, omit event features, or
                            merge the event with another event as it deems necessary without penalty and in such situations,
                            no refunds, part refunds or alternative offers shall be made. If BCF permanently cancels the event
                            for any reason whatever, (including, but not limited to any force majeure occurrence) and provided
                            that the event is not postponed to a later date nor is it merged with another event, the Client shall
                            receive a credit note for the amount that the Client has paid to such permanently canceled event.
                            No refunds, part refunds, or alternative offers shall be made.
                        </p>
                        <h3 className="title-terms">Copyright:</h3>
                        <p className="text-terms last-terms">
                            All intellectual property rights in all materials produced or distributed by BCF in connection
                            with this event are expressly reserved and any unauthorized duplication, publication, or distribution is prohibited.
                        </p>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}

export default Cancelation;
