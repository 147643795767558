import React, { useEffect } from "react";

function Fortuna() {
    useEffect(() => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src = "https://app.hoversignal.com/Api/Script/a25252f1-70c0-46d8-a8dc-a261b563bc62";

        const firstScript = document.getElementsByTagName("script")[0];
        firstScript.parentNode.insertBefore(script, firstScript);

        return () => {
            // Удаляем скрипт при размонтировании компонента
            script.parentNode.removeChild(script);
        };
    }, []);

    return null;
}

export default Fortuna;
