import React, { useEffect, useState } from "react";
import Footer from "../../components/website/Footer";
import HeaderConferences from "../../components/website/HeaderConferences";
import api from "../../api/endpoints/events.js";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Breadcrumbs from "../../components/Breadcrumbs";
import notAvailableImage from "../../assets/images/not-available.jpeg";

function formatDate(dateString) {
  if (!dateString) {
    return ''; // Return an empty string or another appropriate value for null dates
  }
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  return new Date(dateString).toLocaleDateString('en-US', options);
}

function Online() {
  const [events, setEvents] = useState([]);

  const getAllEvents = async () => {
    try {
      const { data } = await api.get("/PastEvents/get-pastevents?type=0");
      const pastEvents = data;
      pastEvents.sort((a, b) => new Date(b.eventDate) - new Date(a.eventDate));
      console.log('pastEvents: ', pastEvents);

      // Concatenate the new pastEvents with existing offlineEvents
      setEvents(pastEvents);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getAllEvents();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <HeaderConferences />
      <main>
        <section>
          <div className="container">
            <div className="breadcrumbs-about breadcrumbs-add">
              <ul>
                <a href="/">
                  <li className="andThisStyle title-breadcrumbs">MAIN</li>
                </a>
                <a href="/events">
                  <li className="andThisStyle description-breadcrumbs second-bread">
                    PAST EVENTS
                  </li>
                </a>
                <li className="thisStyle description-breadcrumbs post-description">
                  ONLINE EVENTS
                </li>
              </ul>
              <Breadcrumbs />
            </div>
            <h2 className="title-conferences title-contact-us event-contact-us">
              Online events
            </h2>
            <div className="line-conferences line-contact-us line-event-us">
              <span className="line-about"></span>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="online-events-wrapper">
              {events?.map((event, index) => (
                <div key={event.id} className="online-item">
                  <img
                      src={`${event.eventImage ? event.eventImage : notAvailableImage}`}
                      alt=""
                  />
                  <h2 className="online-title">{event.eventName}</h2>
                  <div className="date-container">
                    <button className="online-btn">
                      {event.slug === null ? (
                          <a className="conference-link" href={event.eventUrl} target="_blank" rel="noopener noreferrer">
                            Read more
                          </a>
                      ) : (
                          <Link className="conference-link" to={`/single-conference/${event.slug}`} target="_blank" rel="noopener noreferrer">
                            Read more
                          </Link>
                      )}
                    </button>
                    <p className="date-text">{formatDate(event.eventDate)}</p>
                  </div>
              </div>              
            ))}
              {!events.length && (
                <h3 className="title-about title-about-single">No events</h3>
              )}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Online;
