import React from "react";
import Footer from "../../components/website/Footer";
import Header from "../../components/website/Header";
import { Link } from "react-router-dom";
import '@react-pdf-viewer/core/lib/styles/index.css';
import Breadcrumbs from "../../components/Breadcrumbs";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const sections = [
    {
        questions: [
            {
                question: 'VIRTUAL PASS OR RECORDING (POST MATERIALS)',
                answer: (
                    <>
                        <Typography paragraph>
                            <strong>What is the difference between Virtual Pass and Recording?</strong>
                        </Typography>
                        <Typography paragraph>
                            Virtual participation typically refers to live engagement with an event or activity remotely, often through online platforms like video conferencing or live streaming. The virtual pass includes a recording and the virtual delegate will receive it 10 days after the event.
                        </Typography>
                        <Typography paragraph>
                            <strong>The recording</strong>
                        </Typography>
                        <Typography paragraph>
                            is available 10 working days following the event. This time is necessary as our team is in the process of gathering all videos and presentations and preparing a platform.
                        </Typography>
                        <Typography paragraph>
                            <strong>What do "post materials" include?</strong>
                        </Typography>
                        <Typography paragraph>
                            "Post materials" include presentations from speakers, conference session videos, sponsor advertisements or additional information provided by sponsors, and a list of attendees.
                        </Typography>
                    </>
                )
            }
        ]
    },
    {
        questions: [
            {
                question: 'VENUE',
                answer: (
                    <>
                        <Typography paragraph>
                            <strong>Why venue is announced 1 month before the event?</strong>
                        </Typography>
                        <Typography paragraph>
                            The venue is announced one month before the event to allow sufficient time for logistical planning. By announcing the venue at this time, we can accurately assess the expected number of attendees and select an appropriate conference room size that accommodates everyone comfortably. This ensures that we make a well-informed decision regarding the venue, optimizing the conference experience for all participants.
                        </Typography>
                        <Typography paragraph>
                            <strong>Is it possible to book accommodation through BCF?</strong>
                        </Typography>
                        <Typography paragraph>
                            Yes, for further information, please contact <strong>general@bcf-events.com</strong>. You can book accommodation with BCF even if accommodation is not announced yet.
                        </Typography>
                    </>
                )
            }
        ]
    },
    {
        questions: [
            {
                question: 'REGISTRATION',
                answer: (
                    <>
                        <Typography paragraph>
                            <strong>How do I register?</strong>
                        </Typography>
                        <Typography paragraph>
                            You can register by completing the registration form found at the bottom of “REGISTER NOW” on the event page. Choose your preferred method of payment, apply any promo code you may have, and finalize your registration. Our team will connect you within 24 hours for confirmation and provide an invoice or receipt.
                        </Typography>
                        <Typography paragraph>
                            <strong>Do you offer discounted prices for group bookings?</strong>
                        </Typography>
                        <Typography paragraph>
                            Yes, we do offer additional discounts for group bookings of more than 5 attendees. For further information, please reach out to your marketing manager or contact us at General@bcf-events.com.
                        </Typography>
                        <Typography paragraph>
                            <strong>Can we make a booking if we don't know the names of the attendees from our company yet?</strong>
                        </Typography>
                        <Typography paragraph>
                            Yes, you can register with TBA (To Be Announced) and provide the names of the attendees up to 10 days before the event. Please note that if the names are provided less than 10 days before the event, we may not be able to provide a badge.
                        </Typography>
                        <Typography paragraph>
                            <strong>Is delegate substitution permitted?</strong>
                        </Typography>
                        <Typography paragraph>
                            Yes, delegate substitutions are allowed. Please inform us at least 3 days before the event with the details of the substitute delegate.
                        </Typography>
                        <Typography paragraph>
                            <strong>Is it possible to switch from the Virtual Pass to the Delegate Pass or other available packages?</strong>
                        </Typography>
                        <Typography paragraph>
                            Yes, please contact your marketing manager or general@bcf-events.com.
                        </Typography>
                    </>
                )
            }
        ]
    },
    {
        questions: [
            {
                question: 'CONFERENCE',
                answer: (
                    <>
                        <Typography paragraph>
                            <strong>Is there a dress code for the conference?</strong>
                        </Typography>
                        <Typography paragraph>
                            Yes, the conference does have a dress code. We adhere to a "Smart Casual" or "Business" dress code.
                        </Typography>
                    </>
                )
            }
        ]
    }
];

function FAQ() {
    return (
        <Box>
            <Header />
            <main>
                <section>
                    <div className="main-container">
                        <div className="about-background"></div>
                        <div className="background-laptop"></div>
                        <div className="background-mobile"></div>
                        <div className="background-block">
                            <div className="container">
                                <div className="breadcrumbs-about">
                                    <ul>
                                        <li className="andThisStyle">
                                            <Link className="conference-link add-link-breadcrumbs" to="/">
                                                MAIN
                                            </Link>
                                        </li>
                                        <li className="thisStyle">FAQs</li>
                                    </ul>
                                    <Breadcrumbs />
                                </div>
                                <div>
                                    <div style={{ position: "relative" }}>
                                        <h2 className="about-title">FAQs</h2>
                                        <span
                                            className="line-title"
                                            style={{ bottom: "-10px" }}
                                        ></span>
                                        <span
                                            className="line-mobile"
                                            style={{ bottom: "-10px" }}
                                        ></span>
                                    </div>
                                    <Box sx={{ mt: 4, mb: 4 }}>  {/* Отступы сверху и снизу для всего блока вопросов */}
                                        <Grid container spacing={3}>  {/* Увеличенное пространство между элементами */}
                                            {sections.map((section, sectionIndex) => (
                                                section.questions.map((item, index) => (
                                                    <Grid item xs={12} sm={6} key={`${sectionIndex}-${index}`}>
                                                        <Accordion>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls={`panel${sectionIndex}-${index}a-content`}
                                                                id={`panel${sectionIndex}-${index}a-header`}
                                                            >
                                                                <Typography variant="h6">{item.question}</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                {item.answer}
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </Grid>
                                                ))
                                            ))}
                                        </Grid>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </Box>
    );
}

export default FAQ;
