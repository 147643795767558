import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'; // Импортируем useHistory из react-router-dom

function Popup({ handleOpenModal, delegatePopupOpened }) {
    const [showPopup, setShowPopup] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!delegatePopupOpened) {
                setShowPopup(true);
            }
        }, 10000);

        return () => clearTimeout(timer);
    }, [delegatePopupOpened]);

    const closePopup = () => {
        setShowPopup(false);
    };

    const handleOpenAnotherPopup = () => {
        setShowPopup(false);
        if (!delegatePopupOpened) {
            handleOpenModal("delegate");
        }
    };

    const handleOpenSponsorship = () => {
        history.push("/sponsorship");
    };

    if (!showPopup) {
        return null;
    }

    return (
        <div className={`bopup-container ${showPopup ? 'show' : ''}`}>
            <div className="bopup">
                <p>MAKE SURE TO CATCH THE PRE-REGISTRATION PRICE <br/>
                    BEFORE IT EXPIRES.
                </p>
                {/*<p>Текст вашего предложения...</p>*/}
                <button onClick={handleOpenAnotherPopup}>Register now</button>
                <button onClick={handleOpenSponsorship}>Become a sponsor</button>
                <div className="close-btn" onClick={closePopup}>×
                </div>
            </div>
        </div>
    );
}

export default Popup;