import React from 'react';
import { pdf, usePDF, Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import pdflogo from '../assets/pdflogo.png';
import ArialMT from '../assets/fonts/arial/ArialMT.ttf'
import ArialBold from '../assets/fonts/arial/Arial-BoldMT.ttf'
import getDiscountFromPromo from './promocode/promoHelper.js'; // Импортируем хелпер для обработки промокодов

const today = new Date();

const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
};

const formattedToday = formatDate(today);

const dueDate = new Date(today);
dueDate.setDate(today.getDate() + 10);
const formattedDueDate = formatDate(dueDate);

Font.register({
  family: 'Arial',
  fonts: [
    { src: ArialMT  },
    { src: ArialBold, fontWeight: "bold"}
  ]
});


const styles = StyleSheet.create({
  page: {
    fontFamily: 'Arial',
    paddingTop: 0,
    paddingBottom: 60,
    paddingHorizontal: 30,
    fontSize: 9,
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  section: {
    margin: 10,
    padding: 10,
    width: '100%', // Set width to 100%
  },
  note: {
    marginTop: 20,
    fontSize: 9,
  },
  header: {
    fontSize: 24,
    textAlign: 'center',
    marginBottom: 20,
  },
  row: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  column: {
    flexGrow: 1,
  },
  label: {
    width: '40%',
  },
  value: {
    width: '60%',
  },
  smallText: {
    fontSize: 9,
  },
  emailText: {
    fontSize: 9,
    paddingTop: 20,
  },
  boldText: {
    fontWeight: 'bold',
  },
  smallBoldText: {
    fontWeight: 'bold',
  },
  bigBoldText: {
    fontSize: 11,
    fontWeight: 'bold',
  },
  table: {
    display: 'table',
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    paddingVertical: 5,
  },
  tableCell: {
    width: '25%',
    padding: 5,
    textAlign: 'left',
  },
  totalRow: {
    flexDirection: 'row',
    borderTopWidth: 1,
    borderTopColor: '#ccc',
    borderTopStyle: 'solid',
    marginTop: 10,
    paddingTop: 5,
  },
  totalLabel: {
    width: '75%',
    textAlign: 'right',
    fontWeight: 'bold',
  },
  totalValue: {
    width: '25%',
    textAlign: 'left',
    fontWeight: 'bold',
  },
  // Payment data
  paymentDataBlock: {
    backgroundColor: 'rgb(78, 128, 179)',
    color: 'white',
    padding: 8,
    marginTop: 20,
  },
  paymentDataTitle: {
    fontSize: 9,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  paymentDataColumns: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  paymentDataColumn: {
    width: '33%',
    textAlign: 'left',
  },
  paymentDataLabel: {
    fontSize: 9,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  paymentDataValue: {
    fontSize: 9,
    fontWeight: 'bold',
  },
  // Product information
  // Новые стили для блока Product Information
  productInfoBlock: {
    marginTop: 20,
  },
  productInfoTitle: {
    fontSize: 9,
    fontWeight: 'bold',
    marginBottom: 1,
    padding: 5,
  },
  productInfoTable: {
    display: 'table',
    width: '100%',
    borderCollapse: 'collapse',
  },
  productInfoTableHeader: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    paddingVertical: 1,
    fontWeight: 'bold',
  },
  productInfoTableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    paddingVertical: 5,
  },
  productInfoTableCell: {
    padding: 5,
    fontSize: 9,
    justifyContent: 'center',
    alignItems: 'center',
  },
  // New style for the total cell
  productInfoTableCellTotal: {
    padding: 5,
    fontSize: 9,
    backgroundColor: '#c8c7c7', // Grey color
    justifyContent: 'center',
    alignItems: 'center',
  },

  // Total amount block
  totalAmountBlock: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: 'rgb(78, 128, 179)',
    color: 'white',
    width: '50%',
    alignSelf: 'flex-end',
    padding: 10,
    marginTop: 20,
  },
  totalAmountLabel: {
    fontSize: 11,
    fontWeight: 'bold',
    justifyContent: 'center',
    alignItems: 'center',
  },
  totalAmountValue: {
    fontSize: 11,
    fontWeight: 'bold',
  },

  logoContainer: {
    alignSelf: 'flex-start', // Разместить справа
    marginTop: 20, // Отступ сверху
    marginBottom: 20
  },
  logo: {
    width: 100, // Ширина логотипа
    height: 80, // Высота логотипа
  },
  // borderInvoice: {
  //   borderWidth: 2,
  //   borderColor: '#858080',
  //   borderStyle: 'solid',
  //   textAlign: "center",
  // }
  // paddingInformation: {
  //   marginBottom: 132,
  // },
  borderRightInformation: {
    borderLeftWidth: 1,
    borderLeftColor: '#ADB0B3',
    borderLeftStyle: 'solid',
    paddingLeft: 30,
    width: '40%',
  },
  wrapperCustomer: {
    paddingTop: 140,
    paddingLeft: 0,
  },
  vat: {
    fontStyle: 'normal',
  }
});



const generatePDFContent = (bank, invoiceNum, discountedPrice, discount) => (
    <Document>
      <Page size="A4" style="styles.page">
        <View style={styles.section}>
          {/*<Text style={styles.header}>Invoice</Text>*/}

          {/* Supplier and Customer Information */}
          <View style={styles.row}>
            <View style={styles.column}>
              <Text style={[styles.smallText, styles.boldText]}>Supplier:</Text>
              <Text style={[styles.smallText, styles.boldText]}>The winners s.r.o.</Text>
              <Text style={styles.smallText}>ID 17156530</Text>
              <Text style={styles.smallText}>VAT CZ17156530</Text>
              <Text style={styles.smallText}>Address Kubanske Namesti 11, Praha 10, 110 00</Text>

              <View style={styles.logoContainer}>
                <Image src={pdflogo} style={styles.logo} />
              </View>
              <Text style={styles.boldText}>Contact information:</Text>
              <Text style={styles.smallText}>Email: general@bcf-events.com</Text>
              <Text style={styles.smallText}>Phone: +420 214 214 720</Text>
              <Text style={[styles.smallText, {marginTop: 80 }]}>Date of issue: <Text style={[styles.smallBoldText]}>{formattedToday}</Text>  Due date: <Text style={[styles.smallBoldText]}>{formattedDueDate}</Text></Text>
            </View>
            <View style={[styles.column, styles.borderRightInformation]}>
              <View style={styles.flex}>
                <Text>Invoice</Text>
                <Text style={{ paddingRight: 20 }}>{invoiceNum}</Text>
              </View>
              <View style={styles.logoContainer}></View>
              <View style={styles.wrapperCustomer}>
                <Text style={[styles.smallBoldText, {marginBottom: 10}]}>Customer:</Text>
                {/*<Text style={[styles.smallText, styles.boldText]}>{bank.firstName} {bank.lastName}</Text>*/}
                {/*<Text style={styles.smallText}>{bank.phone}</Text>*/}
                {/*<Text style={styles.smallText}>{bank.email}</Text>*/}
                {/*<Text style={styles.smallText}>{bank.job}</Text>*/}
                <Text style={styles.bigBoldText}>{bank.company}</Text>
                <Text style={styles.smallText}>{bank.address}</Text>
                <Text style={styles.smallText}>{bank.city}</Text>
                <Text style={styles.smallText}>VAT ID:</Text>
                <Text style={styles.smallText}>{bank.vat}</Text>
                <Text style={styles.emailText}>Phone: {bank.phone}</Text>
                <Text style={styles.smallText}>Email: {bank.email}</Text>
              </View>
            </View>
          </View>
          {/* Promo Code */}
          {bank.promo && (
              <View style={styles.row}>
                <Text style={[styles.smallText, styles.boldText]}>Promo: </Text>
                <Text style={styles.smallText}>{bank.promo} </Text>
                {discount && discount.type === "fixed" && (
                    <Text style={styles.smallText}>
                      {discount.value.toFixed(2)} EUR off
                    </Text>
                )}
                {discount && discount.type === "percent" && (
                    <Text style={styles.smallText}>
                      {discount.value.toFixed(2)}% off
                    </Text>
                )}
              </View>
          )}


          {/* Payment Data */}
          <View style={styles.paymentDataBlock}>
            <Text style={[styles.paymentDataTitle, { color: 'white' }]}>PAYMENT DATA</Text>
            <View style={styles.paymentDataColumns}>
              {/* 1st column */}
              <View style={styles.paymentDataColumn}>
                <Text style={styles.paymentDataLabel}>Account No. 2191438273/0800</Text>
                <Text style={[styles.paymentDataValue, styles.boldText]}>IBAN: CZ71 0800 0000 0021 9143 8273</Text>
                <Text style={[styles.paymentDataValue, {paddingTop: 20}, styles.boldText]}>SWIFT: GIBACZPX</Text>
              </View>

              {/* 2nd column */}
              <View style={[styles.paymentDataColumn, {paddingLeft: 50 }]}>
                <Text style={styles.paymentDataLabel}>Symbol</Text>
                <Text style={styles.paymentDataValue}>variable: {invoiceNum}</Text>
                <Text style={styles.paymentDataValue}>constant: 0308</Text>
              </View>

              {/* 3rd column */}
              <View style={[styles.paymentDataColumn, {paddingLeft: 50, textAlign: 'left', }]}>
                <Text style={styles.paymentDataLabel}>Payment: Bank transfer</Text>
                <Text style={styles.paymentDataValue}>For payment</Text>
                <Text style={[styles.paymentDataValue, {paddingTop: 5,}]}>{discountedPrice} EUR</Text>
              </View>
            </View>
          </View>

          {/* Product Information */}
          <View style={styles.productInfoBlock}>
            <Text style={styles.productInfoTitle}>An invoice for delivered goods or services:</Text>
            <View style={styles.productInfoTable}>
              {/* Заголовки таблицы */}
              <View style={styles.productInfoTableHeader}>
                <Text style={[styles.productInfoTableCell, { width: '55%', textAlign: 'left' }]}>Delivery name</Text>
                <Text style={[styles.productInfoTableCell, { width: '15%', textAlign: 'center' }]}>Quantity unit</Text>
                <Text style={[styles.productInfoTableCell, { width: '15%', textAlign: 'center' }]}>Unit price</Text>
                <Text style={[styles.productInfoTableCell, { width: '15%', textAlign: 'center' }]}>Total</Text>
              </View>

              {/* Строка с информацией о продукте */}
              <View style={styles.productInfoTableRow}>
                <Text style={[styles.productInfoTableCell, { width: '55%' }]}>{bank.ticket.name} | {bank.conferenceName}</Text>
                <Text style={[styles.productInfoTableCell, { width: '15%', textAlign: 'center' }]}>1</Text>
                <Text style={[styles.productInfoTableCell, { width: '15%', textAlign: 'center' }]}>{bank.ticket.price} EUR</Text>
                <Text style={[styles.productInfoTableCellTotal, styles.boldText, { width: '15%', textAlign: 'center' }]}>{bank.ticket.price} EUR</Text>
              </View>

              {/* Строка с информацией о скидке промокода */}

              <View style={styles.productInfoTableRow}>
                <Text style={[styles.productInfoTableCell, { width: '70%' }]}>
                  Promocode: {bank.promo}
                </Text>
                <Text style={[styles.productInfoTableCell, { width: '15%', textAlign: 'center' }]}></Text>
                <Text style={[styles.productInfoTableCell, { width: '15%', textAlign: 'center' }]}></Text>
                <Text style={[styles.productInfoTableCellTotal, styles.boldText, { width: '15%', textAlign: 'center' }]}>
                  {discount ? (discount.type === 'fixed' ? `${discount.value.toFixed(2)} EUR` : `${discount.value.toFixed(2)}%`) : 'No discount'}
                </Text>
              </View>


              {/* Строка с итоговой суммой */}
              <View style={styles.productInfoTableRow}>
                <Text style={[styles.productInfoTableCell, { width: '70%' }]}>Total amount: </Text>
                <Text style={[styles.productInfoTableCell, { width: '15%', textAlign: 'center' }]}></Text>
                <Text style={[styles.productInfoTableCell, { width: '15%', textAlign: 'center' }]}></Text>
                <Text style={[styles.productInfoTableCellTotal, styles.boldText, { width: '15%', textAlign: 'center' }]}>{discountedPrice.toFixed(2)} EUR</Text>
              </View>
            </View>
          </View>

          {/* Note */}
          <Text style={styles.note}>
            Please note this in case of non-compliance with the due date stated on the invoice we may charge you for statutory interest for delayed payment.
          </Text>
          {/*<Text style={styles.vat}>*Not - VAT Payer</Text>*/}
        </View>

        <View style={styles.totalAmountBlock}>
          <Text style={styles.totalAmountLabel}>Total amount:</Text>
          <Text style={styles.totalAmountValue}>{discountedPrice.toFixed(2)} EUR</Text>
        </View>
      </Page>
    </Document>
);


const InvoicePDF = (bank, invoiceNum) => {
  // Дополнительные проверки
  if (!bank || !invoiceNum) {
    console.error('Неверные входные параметры');
    return null;
  }

  // Получаем скидку из промокода
  const discountInfo = getDiscountFromPromo(bank.promo, bank.conferenceName, bank.ticket.name);

  // Рассчитываем сумму с учетом скидки
  let discountedPrice = parseFloat(bank.ticket.price);

  if (discountInfo && discountInfo.type === "fixed") {
    discountedPrice -= discountInfo.value;
  } else if (discountInfo && discountInfo.type === "percent") {
    const discountPercent = discountInfo.value / 100;
    const discountAmount = discountedPrice * discountPercent;
    discountedPrice -= discountAmount;
  }

  // Вам может потребоваться добавить дополнительные проверки здесь

  try {
    // Попытка генерации PDF
    const blob = pdf(generatePDFContent(bank, invoiceNum, discountedPrice, discountInfo)).toBlob();
    return blob;
  } catch (error) {
    console.error('Ошибка во время генерации PDF:', error);
    return null;
  }
};
export default InvoicePDF;