import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Footer from "../../components/website/Footer";
import Header from "../../components/website/Header";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleDown, faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons';
import { PayPalScriptProvider, PayPalButtons, BraintreePayPalButtons } from "@paypal/react-paypal-js";
import $ from "jquery";
import "magnific-popup";
import "swiper/css";
import { Swiper, SwiperSlide } from 'swiper/react';
import api from "../../api/endpoints/events.js";
import storage from "../../firebase";
import { PDFViewer, pdf } from '@react-pdf/renderer';
import InvoicePDF from "../../components/InvoicePDF";
import { NotificationManager } from 'react-notifications';
import { ThreeDots } from "react-loader-spinner";
import NotFound from "./NotFound";

import {
    listAll,
    ref as storageRef,
    getDownloadURL,
} from "firebase/storage";
import axios from "axios";
import keyImage1 from "../../assets/images/key1.png";
import keyImage2 from "../../assets/images/key2.png";
import keyImage3 from "../../assets/images/key3.png";
import keyImage4 from "../../assets/images/key4.png";
import keyImage5 from "../../assets/images/key5.png";
import keyImage6 from "../../assets/images/key6.png";

// import Rodal from "rodal";
import Modal from "../../components/Modal";

// include styles
import "rodal/lib/rodal.css";

// Import Swiper styles
import "swiper/css";

import { register } from "swiper/element/bundle";
import Breadcrumbs from "../../components/Breadcrumbs";
import Timer from "../../components/Timer";
import PhoneInput from "react-phone-number-input";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import TvIcon from "@mui/icons-material/Tv";
import AssuredWorkloadRoundedIcon from "@mui/icons-material/AssuredWorkloadRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
register();

function PastEvent() {

    const swiperRef = useRef(null);

    const handleImageClick = (index) => {
        console.log('Clicked on image with index:', index);
        const mainSwiper = document.getElementById('mainSwiper');
        if (mainSwiper) {
            mainSwiper.swiper.slideTo(index);
        }};

    const handleImageClickhotel = (index) => {
        console.log('Clicked on image with index:', index);
        const hotelSwiper = document.getElementById('hotelSwiper');
        if (hotelSwiper) {
            hotelSwiper.swiper.slideTo(index);
        }};
    const handleImageClickpartner = (index) => {
        console.log('Clicked on image with index:', index);
        const partnersSwiper = document.getElementById('partnersSwiper');
        if (partnersSwiper) {
            partnersSwiper.swiper.slideTo(index);
        }};


    const invoiceData = {
        invoiceNumber: 'INV123',
        date: '2023-08-24',
        // Add more data as needed
    };
    const { id } = useParams();
    const [conference, setConference] = useState([]);
    const [galleryImages, setGalleryImages] = useState([]);
    const [hotelImages, setHotelsImages] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [activeSlide, setActiveSlide] = useState(0);
    const [isValidId, setIsValidId] = useState(true);


    const agendaInitState = {
        conferenceName: "",
        fullName: "",
        email: "",
        phone: "",
        job: "",
        company: "",
    };
    const speakerNamesToInclude = [
        "Kai Zwingenberger", "Mike Ludlow", "Paolo Pescio", "Dr. J. Susanne Becker", "Jason Creasey", "Paul Hardman",
        "Reem ALGASIM", "Quentin Ankri", "Ana Gloria Sanchez", "Dinara Yarulina", "Jake Bustos", "Milos Rakic",
        "Briony Baxter", "Montserrat Sousa", "Victoria Viana Rios", "Olga Houttuin-Vartanova", "Evy NGUYEN BAQUET",
        "Marielle Legair", "José Domingos Gomes Fernandes", "Professor Helen Day", "Sara Di Paola", "Ethel Changa",
        "Dr. Christian Krueger", "Stephan Roest", "Hans Ridderikhoff", "David Faguer", "William Angleys", "Laura Sena San Miguel",
        "Joana Campos"
    ];
    const VideoPreTeaser = ["INTERNATIONAL HRD SUMMIT"];
    const eventPA = ["5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT CONFERENCE"]
    const eventInhalation = ["7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION"]
    const eventNurse = ["4TH ANNUAL NURSE MANAGEMENT & PERSPECTIVES ON NURSING LEADERSHIP CONFERENCE"]
    const eventAdvancedPlastic = ["ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION"]
    const eventPlantBased = ["INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT"]
    const bankInitState = {
        invoiceNum: "DEFAULT_NUMBER",
        conferenceName: "",
        firstName: "",
        lastName: "",
        email: "",
        job: "",
        company: "",
        address: "",
        city: "",
        vat: "",
        phone: "",
        ticket: { name: "", price: "" },
        promo: "",
    };

    const [agenda, setAgenda] = useState(agendaInitState);
    const [isPresentationModalOpen, setIsPresentationModalOpen] = useState(false);
    const [isAgendaModalOpen, setIsAgendaModalOpen] = useState(false);
    const [bank, setBank] = useState(bankInitState);
    const [currentType, setCurrentType] = useState(null);
    const handleAgenda = (event) => {
        const { name, value } = event.target;
        setAgenda((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handleBank = (event) => {
        const { name, value } = event.target;
        const [paymentName, price] = value.split(':');
        setBank((prevState) => ({
            ...prevState,
            [name]: value,
            conferenceName: conference.eventName,
            ticket: { name: paymentName, price },
        }));
    };
    const handlePhoneChange = (phoneValue) => {
        setBank((prevState) => ({
            ...prevState,
            phone: phoneValue,
        }));
    };
    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    }

    const changeButton = (e) => {
        console.log(e.target.checked);
        setIsChecked(e.target.checked);
    };
    function handleSubmit(e) {
        e.preventDefault(); // Предотвращаем стандартное поведение формы (перезагрузку страницы)

        // Выполняем логику обработки данных формы
        // Например, отправка данных на сервер или другие действия

        // После обработки данных, вы можете перенаправить пользователя или выполнить другие действия
    }
    function handleFormSubmit(e) {
        e.preventDefault(); // Предотвращаем стандартное поведение формы (перезагрузку страницы)

        // Выполняем логику отправки сообщения в Slack
        if (currentType === "presentation") {
            postMessageToSlack(e, "presentation");
        } else if (currentType === "agenda") {
            postMessageToSlack(e, "agenda");
        }

        // Выполняем логику обработки данных формы или другие действия
        handleSubmit(e);
    }
    const keyImages = [
        keyImage1,
        keyImage2,
        keyImage3,
        keyImage4,
        keyImage5,
        keyImage6,
    ];

    const createOrder = (data, actions, price) => {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: price, // Use the passed price value
                    },
                },
            ],
        });
    };

    const onApprove = (data, actions) => {
        // This function is called when the payment is approved
        return actions.order.capture().then(details => {
            console.log('Payment successful', details);
        });
    };

    const fetchImages = async (listRef) => {
        try {
            const result = await listAll(listRef);
            const urlPromises = result.items.map(async (imageRef) => {
                const imageUrl = await getDownloadURL(imageRef);
                return imageUrl;
            });
            return Promise.all(urlPromises);
        } catch (error) {
            console.log('Error fetching images: ', error);
            throw error;
        }
    };

    const generateInvoice = async (bank, invoiceNum) => {
        try {
            console.log('bank before generate pdf: ', bank);
            const pdfBlob = await InvoicePDF(bank, invoiceNum);
            return pdfBlob; // Return the FormData containing the PDF
        } catch (error) {
            console.error('Error generating invoice:', error);
            throw error; // Re-throw the error to be handled in the higher-level function
        }
    };

    const sendToAPI = async (type, url) => {

        let bankName = {
            "Conference": bank.conferenceName,
            "First Name": bank.firstName,
            "Last Name": bank.lastName,
            "Email": bank.email,
            "Job Title": bank.job,
            "Company": bank.company,
            "Company Address": bank.address,
            "City": bank.city,
            "VAT": bank.vat,
            "Phone": bank.phone,
            "Ticket Type": { "Ticket Name": bank.ticket.name, "Ticket Price": bank.ticket.price },
            "Promo": bank.promo
        }

        const invoiceNumber = await api.get(`${url}User/invoice/get-number`);
        const inNum = invoiceNumber.data.toString();
        console.log('invoice number: ', inNum);
        setBank((prevState) => ({
            ...prevState,
            invoiceNum: inNum,
        }));

        setIsLoading(true);

        try {

            const pdfBlob = await generateInvoice(bank, inNum);
            const formData = new FormData();
            formData.append('data', JSON.stringify(bankName));
            formData.append('pdfFile', pdfBlob, 'invoice.pdf');

            console.log('bankData is: ', bankName);

            const response = await axios.post(`${url}User/slack-pdf`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            console.log('response from endpoint:', response);
            setIsModalOpen(false);
            document.querySelector(".header").style.zIndex = "3";
            if(type === "agenda") {
                NotificationManager.success('Thank you for your request!\n', 'Success', 5000);
            }
            else {
                NotificationManager.success('Thank you for your registration!\n' +
                    'The invoice has been sent to your email.', 'Success', 5000);
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error sending request to API:', error);
            setIsLoading(false);
            // Handle error as needed
        }
    };


    const fetchPdf = async (eventId) => {
        try {
            const pdfRef = storageRef(storage, `gs://sns-cz.appspot.com/Agenda/${eventId}.pdf`);
            const pdfUrl = await fetchPdfUrl(pdfRef);
            return pdfUrl;
        } catch (error) {
            console.log('Error fetching PDF: ', error);
        }
    };

    const fetchPdfUrl = async (pdfRef) => {
        try {
            const pdfUrl = await getDownloadURL(pdfRef);
            return pdfUrl;
        } catch (error) {
            throw error;
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                window.scrollTo(0, 0);

                const { data } = await api.get(`/Event/get-event-by-slug/${id}`);
                if(data.endDate == null) {
                    setIsValidId(false);
                    return;
                }
                setConference(data);
                console.log('Conference id: ', data);

                setAgenda((prevState) => ({
                    ...prevState,
                    conferenceName: data.eventName,
                }));

                const conferenceGalleryListRef = storageRef(storage, `gs://sns-cz.appspot.com/Images/${data.id}`);
                const hotelId = data.hotels[0]?.id;
                const hotelGalleryListRef = storageRef(storage, `gs://sns-cz.appspot.com/Images/${hotelId}`);

                const conferenceGalleryImages = await fetchImages(conferenceGalleryListRef);
                const hotelGalleryImages = await fetchImages(hotelGalleryListRef);

                setGalleryImages(conferenceGalleryImages);
                setHotelsImages(hotelGalleryImages);

                console.log('Gallery images: ', galleryImages);
            } catch (error) {
                console.log('Error fetching data: ', error);
                setIsValidId(false);
            }
        };

        fetchData();
    }, []);


    useEffect(() => {
        $(".popup-with-zoom-anim").magnificPopup({
            type: "inline",
            fixedContentPos: false,
            fixedBgPos: true,
            overflowY: "auto",
            closeBtnInside: false,
            closeMarkup:
                '<button title="%title%" type="button" class="mfp-close close-button"> <div class="line1"></div> <div class="line2"></div> </button>',
            preloader: false,
            midClick: true,
            removalDelay: 300,
            autoOpen: false,
            mainClass: "my-mfp-fade-in",
            callbacks: {
                close: function () {
                    $("html").removeClass("mfp-open");
                },
            },
        });
    });

    useEffect(() => {
        const overlay = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    const popUps = document.querySelector('.modal, .mfp-bg');
                    if (popUps) {
                    } else {
                        document.body.style.overflow = 'auto';
                    }
                }
            }
        });

        overlay.observe(document.body, { childList: true, subtree: true });

        return () => overlay.disconnect();
    }, []);

    const formateDate = (date) => {
        if (!date.startDate || !date.endDate) {
            return '';
        }

        const { startDate, endDate } = date;
        const formattedDate = new Date(startDate);
        const [startDay, endDay] = [
            formattedDate.getDate(),
            new Date(endDate).getDate(),
        ];
        return ` ${formattedDate.toLocaleString("en-US", { month: "long" })}
     ${startDay} -
     ${endDay}, ${new Date(endDate).getFullYear()}`;
    };

    const postMessageToSlack = async (e, type) => {
        e.preventDefault();
        let url = '';
        if (window.location.hostname === 'localhost') {
            url = 'https://api.bcf-events.com/';
            // url = 'https://localhost:7264/';
        }
        if (window.location.hostname === 'green-desert-021865b10.3.azurestaticapps.net'
            || window.location.hostname === 'brave-dune-00ae5aa10.3.azurestaticapps.net'
            || window.location.hostname === 'new.bcf-events.com'
            || window.location.hostname === 'bcf-events.com'
        ) {
            url = 'https://api.bcf-events.com/';
        }

        try {
            let agendaName = {
                "PastEvent Conference": agenda.conferenceName,
                "Full Name": agenda.fullName,
                "Email": agenda.email,
                "Phone": agenda.phone,
                "Job Title": agenda.job,
                "Company": agenda.company,
            };

            if (type === "presentation") {
                console.log("Before sending to Slack");
                // Вызываем postMessageToSlack и ждем его завершения
                const response = await axios.post(`${url}User/slack/${type}`, agendaName);
                console.log("After sending to Slack, response:", response);

                // Проверяем успешность отправки в Slack
                if (response !== null && response !== undefined) {
                    window.open(`/postmaterial/${conference.slug}`, '_blank');
                }
            } else if (type === "agenda") {
                setAgenda(agendaInitState);
                const response = await axios.post(`${url}User/slack/${type}`, agendaName);
                console.log('response from endpoint: ', response);

                const anchor = document.createElement("a");
                const urlpdf = await fetchPdf(conference.id);
                anchor.href = urlpdf;
                anchor.download = "agenda.pdf";
                anchor.style.display = "none";
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
            } else {
                // Вызываем sendToAPI
                await sendToAPI(type, url);
            }
        } catch (error) {
            console.error("Error sending message to Slack:", error);
        }
    };
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = (type) => {
        console.log("Opening modal for type:", type);
        setCurrentType(type);
        setIsModalOpen(true);
        document.querySelector(".header").style.zIndex = "1";
    };
    useEffect(() => {
        if (isModalOpen) {

        }
    }, [isModalOpen]);
    const handleCloseModal = () => {
        setIsModalOpen(false);
        document.querySelector(".header").style.zIndex = "3";
    };

    useEffect(() => {
        const listItems = document.querySelectorAll('.thisStyle, .andThisStyle');

        listItems.forEach(item => {
            const line = item.querySelector('.line-breadcrumbs');
            if(line) {
                line.style.width = item.offsetWidth + 'px';
            }
        });
    }, []);

    const [diffInSeconds, setDiffInSeconds] = useState(null);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const now = new Date();
            const endDate = new Date(conference.endDate);

            const diffInMilliseconds = endDate.getTime() - now.getTime();
            const calculatedDiffInSeconds = Math.floor(diffInMilliseconds / 1000);

            setDiffInSeconds(calculatedDiffInSeconds * 1000);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [conference.endDate]);

    if (!isValidId) {
        return <NotFound />;
    }

    return (
        <div>
            <Header />
            <main>
                {/* <PDFViewer width="100%" height={500}>
        <InvoicePDF invoiceData={invoiceData} />
      </PDFViewer> */}
                <section name="main">
                    <div className="main-container">
                        <div
                            className="background-single"
                            style={{
                                backgroundImage: `url(${conference.eventImage})`,
                            }}
                        ></div>
                        <div className="background-block">
                            <div className="container">
                                <div className="breadcrumbs">
                                    <ul>
                                        <li className="andThisStyle">
                                            <Link className="conference-link" to="/">
                                                MAIN
                                            </Link>
                                        </li>
                                        <li className="thisStyle">{conference.eventName}</li>
                                    </ul>
                                    <Breadcrumbs conferenceName={conference.eventName} />
                                </div>
                                <div className="title">
                                    <h1>{conference.eventName}</h1>
                                    <span className="line-title"></span>
                                    <span className="mobile-title-line"></span>
                                    <p>
                                        {" "}
                                        {formateDate(conference)}
                                    </p>
                                </div>
                                <div className="action-block-online block-icons-div">
                                    <ul>
                                        <li onClick={() => handleOpenModal("presentation")} className="download-agenda">
                                            <span className="icon">
                                            <img
                                                src={require("../../assets/images/adgenda.png")}
                                                alt=""
                                            />
                                                  </span>
                                            <p>
                                               LOG IN
                                            </p>
                                        </li>
                                        <li
                                            className="become-delegate"
                                            onClick={() => handleOpenModal("delegate")}
                                        >
                                          <span className="icon">
                                            <img
                                                src={require("../../assets/images/delegate.png")}
                                                alt=""
                                            />
                                          </span>
                                            <p>BUY RECORDING</p>
                                        </li>
                                    </ul>

                                </div>
                                <Modal
                                    types="delegate"
                                    currentType={currentType}
                                    isOpen={isModalOpen}
                                    onClose={handleCloseModal}
                                >
                                    <div
                                        id="delegate-dialog"
                                        className=" special-style"
                                    >
                                        <div className="popup-container"></div>
                                        <Link
                                            className="conference-link conference-data-link"
                                            to={`/stripe/${conference.slug}`}
                                        >
                                            <div className="popup-item item-1">
                                                <span className="text-popup">CREDIT CARD</span>
                                            </div>
                                        </Link>
                                        <div
                                            className="popup-item item-2"
                                            onClick={() => handleOpenModal("pay-pal")}
                                        >
                                            <span className="text-popup">PAYPAL</span>
                                        </div>
                                        <div
                                            className="popup-item item-3"
                                            onClick={() => handleOpenModal("bank-transfer")}
                                            // className="popup-item item-3 disabled"
                                            // onClick={() => handleOpenBankTransfer("bank-transfer")}
                                        >
                                            <span className="text-popup">BANK TRANSFER</span>
                                        </div>

                                    </div>
                                </Modal>
                                <Modal
                                    types="pay-pal"
                                    currentType={currentType}
                                    isOpen={isModalOpen}
                                    onClose={handleCloseModal}
                                >
                                    <div
                                        //id="paypal-dialog"
                                        //className="zoom-anim-dialog mfp-hide my-custom-popup-styles"
                                    >
                                        {conference.payPalPayments && conference.payPalPayments.length > 0 && (
                                            <div className="paypal-square">
                                                <div className="scroll-container-wrapper">
                                                    {/*<div className="scroll-container">*/}
                                                    {/*</div>*/}
                                                    <div className="scroll-container">
                                                        {conference?.payPalPayments?.map((pay) => (
                                                            <div key={pay.id} className="paypal-wrapper">
                                                                <div>
                                                                    <h4>{pay.paymentName}</h4>
                                                                    <p className="price-paypal">1 x {pay.price} EUR</p>
                                                                </div>
                                                                <div className="paypal-width">
                                                                    <PayPalScriptProvider options={{ clientId: "AYoVCFKvcXHDj0pcFTGhsqYjaH4dJnWibwLvIda5WAlELIXpQcqUM_y37SpKrdhB_3ts2RiH6kpYwf1i", currency: "EUR" }}>
                                                                        <PayPalButtons
                                                                            createOrder={(data, actions) => createOrder(data, actions, pay.price)}
                                                                            onApprove={onApprove}
                                                                            style={{ layout: "horizontal" }}
                                                                        />
                                                                    </PayPalScriptProvider>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Modal>
                                <Modal
                                    types="bank-transfer"
                                    currentType={currentType}
                                    isOpen={isModalOpen}
                                    onClose={handleCloseModal}
                                >
                                    <div className="bank-style  special-style">
                                        <div className="white-square">
                                            <div className="scroll-container-wrapper">
                                                <div className="scroll-container add-mobile-wrapper">

                                                    <form
                                                        className="form-bank"
                                                        onSubmit={(e) =>
                                                            postMessageToSlack(e, "bank-transfer")
                                                        }
                                                    >
                                                        <div className="form-group">
                                                            <label
                                                                htmlFor="first-name"
                                                                className="first-name-class"
                                                            >
                                                                First Name*
                                                            </label>
                                                            <input
                                                                type="text"
                                                                id="first-name"
                                                                name="firstName"
                                                                required
                                                                value={bank.firstName}
                                                                onChange={(e) => handleBank(e)}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="last-name">Last Name*</label>
                                                            <input
                                                                type="text"
                                                                id="last-name"
                                                                name="lastName"
                                                                required
                                                                value={bank.lastName}
                                                                onChange={(e) => handleBank(e)}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="email">Email*</label>
                                                            <input
                                                                type="email"
                                                                id="email"
                                                                name="email"
                                                                required
                                                                value={bank.email}
                                                                onChange={(e) => handleBank(e)}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="job-title">Job Title*</label>
                                                            <input
                                                                type="text"
                                                                id="job-title"
                                                                name="job"
                                                                required
                                                                value={bank.job}
                                                                onChange={(e) => handleBank(e)}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="company-name">
                                                                Company Name*
                                                            </label>
                                                            <input
                                                                type="text"
                                                                id="company-name"
                                                                name="company"
                                                                required
                                                                value={bank.company}
                                                                onChange={(e) => handleBank(e)}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="company-address">
                                                                Company Address*
                                                            </label>
                                                            <input
                                                                type="text"
                                                                id="company-address"
                                                                name="address"
                                                                required
                                                                value={bank.address}
                                                                onChange={(e) => handleBank(e)}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="city">City*</label>
                                                            <input
                                                                type="text"
                                                                id="city"
                                                                name="city"
                                                                required
                                                                value={bank.city}
                                                                onChange={(e) => handleBank(e)}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="vat">
                                                                VAT of the company (only for EU company)
                                                            </label>
                                                            <input
                                                                type="text"
                                                                id="vat"
                                                                name="vat"
                                                                value={bank.vat}
                                                                onChange={(e) => handleBank(e)}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="phone">Phone*</label>
                                                            <PhoneInput
                                                                type="tel"
                                                                id="phone"
                                                                name="phone"
                                                                required
                                                                value={bank.phone}
                                                                onChange={handlePhoneChange}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="promo">Promo</label>
                                                            <input
                                                                type="text"
                                                                id="promo"
                                                                name="promo"
                                                                value={bank.promo}
                                                                onChange={(e) => handleBank(e)}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="ticket-type">Ticket Type*</label>
                                                            <select
                                                                id="ticket-type"
                                                                name="ticket"
                                                                required
                                                                value={bank.ticket.name !== "" ? `${bank.ticket.name}:${bank.ticket.price}` : ""}
                                                                onChange={(e) => handleBank(e)}
                                                            >
                                                                <option value="">Select one</option>
                                                                {conference?.payPalPayments?.map((payment, index) => (
                                                                    <option key={`option-${index}`} value={`${payment.paymentName}:${payment.price}`}>
                                                                        {`${payment.paymentName}: ${"€" + payment.price}`}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="form-group1">
                                                            <label class="galochka">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-checkbox"
                                                                    onChange={handleCheckboxChange}
                                                                    checked={isChecked}
                                                                />
                                                                I agree with&nbsp;<a href="https://bcf-events.com/terms">Terms and Conditions</a>
                                                            </label>
                                                        </div>
                                                        <button
                                                            type="submit"
                                                            className={`register-button ${isChecked ? 'active' : 'inactive'}`}
                                                            disabled={!isChecked}
                                                        >
                                                            { isLoading ? <ThreeDots  height="40" width="40" color="white" /> : "Register"}
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>

                                <Modal
                                    types={["presentation", "agenda", /* другие типы, если необходимо */]}
                                    currentType={currentType}
                                    isOpen={isModalOpen}
                                    onClose={handleCloseModal}
                                >
                                    <div className="white-square">
                                        <div className="scroll-container-wrapper">
                                            <div className="scroll-container">
                                                <form
                                                    className="form-bank"
                                                    onSubmit={(e) => handleFormSubmit(e)}>
                                                    <div className="form-group">
                                                        <label
                                                            htmlFor="firstName"
                                                            className="first-name-class"
                                                        >
                                                            Full Name*
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="first-name-input"
                                                            name="fullName"
                                                            required
                                                            value={agenda.firstName}
                                                            onChange={(e) => handleAgenda(e)}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="email">Email*</label>
                                                        <input
                                                            type="email"
                                                            id="email-input"
                                                            name="email"
                                                            required
                                                            value={agenda.email}
                                                            onChange={(e) => handleAgenda(e)}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="phone">Phone*</label>
                                                        <input
                                                            type="number"
                                                            id="phone"
                                                            name="phone"
                                                            required
                                                            value={agenda.phone}
                                                            onChange={(e) => handleAgenda(e)}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="job-title">Job Title*</label>
                                                        <input
                                                            type="text"
                                                            id="job-title-input"
                                                            name="job"
                                                            required
                                                            value={agenda.job}
                                                            onChange={(e) => handleAgenda(e)}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="company-name">Company Name*</label>
                                                        <input
                                                            type="text"
                                                            id="company-name-input"
                                                            name="company"
                                                            required
                                                            value={agenda.company}
                                                            onChange={(e) => handleAgenda(e)}
                                                        />
                                                    </div>
                                                    <button type="submit" className="canister-button">
                                                        { isLoading ? <ThreeDots  height="40" width="40" color="white" /> : "Request" }
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div id="price" className="container">
                        <ul className="packages-container">
                            {conference?.payPalPayments?.filter(payment =>
                                !payment.paymentName.includes('Sponsorship') && !payment.paymentName.includes('Sponsorhip')
                            ).map((payment, index) => (
                                <li key={index} className="packages-item-conference">
                                    <h3 className="sponsor-title-conference">
                                        {payment.paymentName.replace('participating', '').replace('Pass', '').trim()}
                                    </h3>
                                    <div className="line-container">
                                        <span className="line-sponsorship"></span>
                                    </div>
                                    <p className="sponsor-price">{"€" + payment.price}</p>
                                    {/*<div className="line-container">*/}
                                    {/*  <span className="line-sponsorship"></span>*/}
                                    {/*</div>*/}
                                    <button onClick={() => handleOpenModal("delegate")} className="sponsors-button">Buy Recording</button>
                                    <ul className="sponsors-list">
                                        {eventInhalation.includes(conference.eventName) && (
                                            <div className="second_title">
                                                {payment.paymentName.includes('OEM') && (
                                                    <ul className="second_ditle_list">
                                                        <AccountBalanceRoundedIcon color="primary" fontSize="large" />
                                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                                        <li>2 Days of The Conference</li>
                                                        <li>2 Lunches</li>
                                                        <li>Post Materials</li>
                                                        <li>Recording</li>
                                                        <li>Prosecco Round Table</li>
                                                        <li>Cocktail Reception</li>
                                                    </ul>
                                                )}
                                                {payment.paymentName.includes('Standard') && (
                                                    <ul className="second_ditle_list">
                                                        <AssuredWorkloadRoundedIcon color="primary" fontSize="large"/>
                                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                                        <li>2 Days of The Conference</li>
                                                        <li>2 Lunches</li>
                                                        <li>Post Materials</li>
                                                        <li>Recording</li>
                                                        <li>Prosecco Round Table</li>
                                                        <li>Cocktail Reception</li>
                                                        <li>Ice Breaker Session (Day Before Conference)</li>
                                                    </ul>
                                                )}
                                                {payment.paymentName.includes('VIP') && (
                                                    <ul className="second_ditle_list">
                                                        <CheckCircleOutlineRoundedIcon color="primary" fontSize="large"/>
                                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                                        <li>2 Days of The Conference</li>
                                                        <li>2 Lunches</li>
                                                        <li>Post Materials</li>
                                                        <li>Recording</li>
                                                        <li>Prosecco Round Table</li>
                                                        <li>Cocktail Reception</li>
                                                        <li>Ice Breaker Session (Day Before Conference)</li>
                                                        <li>2 Nights Accommodation</li>
                                                        <li>Transfer From/To Airport</li>
                                                        <li>Celebration Party</li>
                                                    </ul>
                                                )}
                                                {payment.paymentName.includes('Recording') && (
                                                    <ul className="second_ditle_list">
                                                        <VideoLibraryIcon color="primary" fontSize="large" />
                                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                                        <li>Speakers' Presentations</li>
                                                        <li>Video Recording</li>
                                                    </ul>
                                                )}
                                            </div>
                                        )}
                                        {eventPA.includes(conference.eventName) && (
                                            <div className="second_title">
                                                {payment.paymentName.includes('Person') || payment.paymentName.includes('Basic')  && (
                                                    <ul className="second_ditle_list">
                                                        <AccountBalanceRoundedIcon color="primary" fontSize="large" />
                                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                                        <li>2 Days of The Conference</li>
                                                        <li>2 Lunches</li>
                                                        <li>Post Materials</li>
                                                        <li>Recording</li>
                                                        <li>Prosecco round table</li>
                                                        <li>Cocktail Reception</li>
                                                    </ul>
                                                )}
                                                {payment.paymentName.includes('Standard') && (
                                                    <ul className="second_ditle_list">
                                                        <AssuredWorkloadRoundedIcon color="primary" fontSize="large"/>
                                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                                        <li>2 Days of The Conference</li>
                                                        <li>2 Lunches</li>
                                                        <li>Post Materials</li>
                                                        <li>Recording</li>
                                                        <li>Prosecco round table</li>
                                                        <li>Cocktail Reception</li>
                                                        <li>Certificated Workshop (day before conference)</li>
                                                    </ul>
                                                )}
                                                {payment.paymentName.includes('VIP') && (
                                                    <ul className="second_ditle_list">
                                                        <CheckCircleOutlineRoundedIcon color="primary" fontSize="large"/>
                                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                                        <li>2 Days of The Conference</li>
                                                        <li>2 Lunches</li>
                                                        <li>Post Materials</li>
                                                        <li>Recording</li>
                                                        <li>Prosecco round table</li>
                                                        <li>Cocktail Reception</li>
                                                        <li>Certificated Workshop (day before conference)</li>
                                                        <li>2 Nights Accommodation</li>
                                                    </ul>
                                                )}
                                                {payment.paymentName.includes('Recording') && (
                                                    <ul className="second_ditle_list">
                                                        <VideoLibraryIcon color="primary" fontSize="large" />
                                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                                        <li>Speakers' Presentations</li>
                                                        <li>Video Recording</li>
                                                    </ul>
                                                )}
                                            </div>
                                        )}
                                        {eventAdvancedPlastic.includes(conference.eventName) && (
                                            <div className="second_title">
                                                {payment.paymentName.includes('Person') || payment.paymentName.includes('Basic')  && (
                                                    <ul className="second_ditle_list">
                                                        <AccountBalanceRoundedIcon color="primary" fontSize="large" />
                                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                                        <li>2-Day Delegate Pass</li>
                                                        <li>Post Materials with Recording</li>
                                                        <li>Networking Lunches</li>
                                                    </ul>
                                                )}
                                                {payment.paymentName.includes('Standard') && (
                                                    <ul className="second_ditle_list">
                                                        <AssuredWorkloadRoundedIcon color="primary" fontSize="large"/>
                                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                                        <li>2-Day Delegate Pass</li>
                                                        <li>Pre-conference Networking (23rd April)</li>
                                                        <li>Post Materials with Recording</li>
                                                        <li>Networking Lunches</li>
                                                        <li>Evening Reception</li>
                                                    </ul>
                                                )}
                                                {payment.paymentName.includes('VIP') && (
                                                    <ul className="second_ditle_list">
                                                        <CheckCircleOutlineRoundedIcon color="primary" fontSize="large"/>
                                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                                        <li>2-Day Delegate Pass</li>
                                                        <li>Pre-conference Networking (23rd April)</li>
                                                        <li>Nights at the Venue Hotel</li>
                                                        <li>Transfer “from Airport – To Airport”</li>
                                                        <li>Post Materials with Recording</li>
                                                        <li>Networking Lunches</li>
                                                        <li>Evening Reception</li>
                                                        <li>Christmas Industry Celebration</li>
                                                    </ul>
                                                )}
                                                {payment.paymentName.includes('Recording') && (
                                                    <ul className="second_ditle_list">
                                                        <VideoLibraryIcon color="primary" fontSize="large" />
                                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                                        <li>Speakers' Presentations</li>
                                                        <li>Video Recording</li>
                                                    </ul>
                                                )}
                                            </div>
                                        )}
                                            <div className="second_title">
                                                {payment.paymentName.includes('Person') && (
                                                    <ul className="second_ditle_list">
                                                        <AccountBalanceRoundedIcon color="primary" fontSize="large" />
                                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                                        <li>In Person Participation</li>
                                                        <li>Speakers' Presentations</li>
                                                        <li>Video Recording</li>
                                                        <li>Evening Reception</li>
                                                    </ul>
                                                )}
                                                {(payment.paymentName.includes('DELEGATE') || payment.paymentName.includes('DELEGATES')
                                                    || payment.paymentName.includes('delegates') || payment.paymentName.includes('delegate')) && (
                                                    <ul className="second_ditle_list">
                                                        <AssuredWorkloadRoundedIcon color="primary" fontSize="large"/>
                                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                                        <li>2 Days of The Conference</li>
                                                        <li>2 Networking Lunches</li>
                                                        <li>Speakers' Presentations</li>
                                                        <li>Prosecco Round Table</li>
                                                        <li>Cocktail Reception</li>
                                                        <li>Ice Breaker Session (Day Before Conference)</li>
                                                    </ul>
                                                )}
                                                {payment.paymentName.includes('Virtual') && (
                                                    <ul className="second_ditle_list">
                                                        <TvIcon color="primary" fontSize="large" />
                                                        <h3 style={{color: 'burlywood'}}>This includes::</h3>
                                                        <li>Virtual Participation</li>
                                                        <li>Speakers' Presentations</li>
                                                        <li>Video Recording</li>
                                                    </ul>
                                                )}
                                                {payment.paymentName.includes('Recording') && (
                                                    <ul className="second_ditle_list">
                                                        <VideoLibraryIcon color="primary" fontSize="large" />
                                                        <h3 style={{color: 'burlywood'}}>This includes:</h3>
                                                        <li>Speakers' Presentations</li>
                                                        <li>Video Recording</li>
                                                    </ul>
                                                )}
                                            </div>
                                    </ul>
                                </li>
                            ))}
                        </ul>

                    </div>
                </section>
                <section className="section-keys">
                    <div className="key-container container">
                        <div className="key-container-block">
                            <h3 className="key-title">KEY POINTS</h3>
                            <ul>
                                {conference.keyPoints?.map((point, index) => (
                                    <div
                                        key={point.id}
                                        className={`key-block ${index > 2 && !showAll ? 'hidden' : ""}`}>
                                        <img
                                            src={`${keyImages[index % keyImages.length]}`}
                                            alt=""
                                        />
                                        <li>{point.keyName}</li>
                                    </div>
                                ))}
                                {conference.keyPoints?.length > 2 && (
                                    <li className={`key-point-btn ${!showAll ? 'btn-hidden' : ""}`}>
                        <span onClick={() => setShowAll(!showAll)}>
                          {showAll ? <FontAwesomeIcon icon={faAngleDoubleUp} /> : <FontAwesomeIcon icon={faAngleDoubleDown} />}
                        </span>
                                    </li>
                                )}
                            </ul>
                        </div>
                        <span className="vertical-line"></span>
                        <div className="mobile-line-container">
                            <span className="mobile-title-line"></span>
                        </div>
                        <div className="key-container-block">
                            <h3 className="key-title">WHO SHOULD ATTEND</h3>
                            <ul>
                                {conference.attendReasons?.map((reason, index) => (
                                    <div
                                        key={reason.id}
                                        className={`key-block ${index > 2 && !showAll ? 'hidden' : ""}`}>
                                        <img
                                            src={`${keyImages[index % keyImages.length]}`}
                                            alt=""
                                        />
                                        <li>{reason.reason}</li>
                                    </div>
                                ))}
                                {conference.attendReasons?.length > 2 && (
                                    <li className={`key-point-btn ${!showAll ? 'btn-hidden' : ""}`}>
                        <span onClick={() => setShowAll(!showAll)}>
                          {showAll ? <FontAwesomeIcon icon={faAngleDoubleUp} /> : <FontAwesomeIcon icon={faAngleDoubleDown} />}
                        </span>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}
// ещё
export default PastEvent;